var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      class: {
        "active-product-card": _vm.subscriptionTransformation === "Same",
      },
      attrs: {
        light: "",
        elevation: "3",
        rounded: "",
        color: "white",
        disabled: _vm.subscriptionTransformation === "Downgrade",
      },
    },
    [
      _vm.subscriptionTransformation === "Same"
        ? _c(
            "v-system-bar",
            {
              staticClass: "d-flex justify-center",
              attrs: { color: "tertiary", height: "24" },
            },
            [
              _c(
                "span",
                { staticClass: "text-uppercase font-weight-bold white--text" },
                [_vm._v("Currently Active")]
              ),
            ]
          )
        : _vm.subscriptionTransformation === "Upgrade"
        ? _c(
            "v-system-bar",
            {
              staticClass: "d-flex justify-center",
              attrs: { color: "#43a047", height: "24" },
            },
            [
              _c(
                "span",
                { staticClass: "text-uppercase font-weight-bold white--text" },
                [_vm._v("Upgrade")]
              ),
            ]
          )
        : _vm._e(),
      _c("v-card-title", { staticClass: "justify-center" }, [
        _c(
          "div",
          { staticClass: "d-flex flex-column justify-center my-10" },
          [
            _vm.product.image
              ? _c(
                  "v-icon",
                  {
                    staticClass: "my-2",
                    attrs: { "x-large": "", color: "tertiary" },
                  },
                  [_vm._v("fa-light " + _vm._s(_vm.product.image))]
                )
              : _vm._e(),
            _c("h1", { staticClass: "text-center my-1" }, [
              _vm._v(_vm._s(_vm.product.name)),
            ]),
            _vm.product.description
              ? _c("span", { staticClass: "text-center my-1" }, [
                  _vm._v(_vm._s(_vm.product.description)),
                ])
              : _vm._e(),
            _vm.product.billingPlan === "Free"
              ? _c("div", { staticClass: "d-block text-center my-4" }, [
                  _c("span", { staticStyle: { "font-size": "30px" } }, [
                    _vm._v("Free"),
                  ]),
                ])
              : _c("div", { staticClass: "d-block text-center my-4" }, [
                  _vm.product.currency === "Euro"
                    ? _c(
                        "span",
                        {
                          staticClass: "mr-2",
                          staticStyle: { "font-size": "30px" },
                        },
                        [_vm._v("€")]
                      )
                    : _vm._e(),
                  _c(
                    "span",
                    {
                      staticClass: "mr-2",
                      staticStyle: { "font-size": "30px" },
                    },
                    [_vm._v(_vm._s(_vm.product.price))]
                  ),
                  _vm.product.billingPlan === "Monthly"
                    ? _c("span", [_vm._v("/month")])
                    : _vm._e(),
                ]),
          ],
          1
        ),
      ]),
      _c(
        "v-card-text",
        { staticClass: "justify-center" },
        [
          _c(
            "v-list",
            { attrs: { color: "transparent" } },
            _vm._l(_vm.featureItems, function (featureItem, index) {
              return _c(
                "v-list-item",
                { key: index, attrs: { dense: "" } },
                [
                  _c(
                    "v-list-item-icon",
                    { staticClass: "mr-2" },
                    [
                      featureItem.type === "Included"
                        ? _c(
                            "v-icon",
                            { attrs: { color: "green darken-1", small: "" } },
                            [_vm._v("fa-light fa-check")]
                          )
                        : _vm._e(),
                      featureItem.type === "Excluded"
                        ? _c(
                            "v-icon",
                            { attrs: { color: "red darken-1", small: "" } },
                            [_vm._v("fa-light fa-xmark")]
                          )
                        : _vm._e(),
                      featureItem.type === "Limitation"
                        ? _c(
                            "v-icon",
                            { attrs: { color: "orange darken-1", small: "" } },
                            [_vm._v("fa-light fa-triangle-exclamation")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item-content",
                    [
                      _c("v-list-item-title", [
                        _vm._v(" " + _vm._s(featureItem.description) + " "),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
      _c("v-spacer"),
      _c(
        "v-card-actions",
        [
          _vm.product.action === "SignedOff"
            ? _c(
                "v-btn",
                {
                  attrs: {
                    depressed: "",
                    dark: "",
                    color: "tertiary",
                    width: "100%",
                    to: { name: "Search" },
                  },
                },
                [_vm._v("Get Started")]
              )
            : _vm.product.action === "SignedIn"
            ? _c(
                "v-btn",
                {
                  attrs: {
                    depressed: "",
                    dark: "",
                    color: "tertiary",
                    width: "100%",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.$auth.signIn()
                    },
                  },
                },
                [_vm._v("Sign In")]
              )
            : _vm.product.action === "Purchase"
            ? [
                _vm.subscriptionTransformation === "Subscribe"
                  ? _c(
                      "v-btn",
                      {
                        attrs: {
                          depressed: "",
                          dark: "",
                          color: "tertiary",
                          width: "100%",
                          to: {
                            name: "Subscribe",
                            query: { product: _vm.product.key },
                          },
                        },
                      },
                      [_vm._v("Subscribe")]
                    )
                  : _vm._e(),
                _vm.subscriptionTransformation === "Upgrade"
                  ? _c(
                      "v-btn",
                      {
                        attrs: {
                          depressed: "",
                          dark: "",
                          color: "#43a047",
                          width: "100%",
                          to: {
                            name: "Subscribe",
                            query: { product: _vm.product.key },
                          },
                        },
                      },
                      [_vm._v("Upgrade")]
                    )
                  : _vm._e(),
              ]
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }