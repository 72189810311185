var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-main", [
    _c(
      "section",
      {
        staticClass: "theme--light default-vertical-padding",
        attrs: { id: "brands-de" },
      },
      [
        _c(
          "v-container",
          { attrs: { fluid: "" } },
          [
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { attrs: { cols: "12" } },
                  [
                    _c(
                      "v-layout",
                      {
                        staticClass: "headline",
                        attrs: {
                          row: "",
                          "justify-center": "",
                          "data-aos": "fade-down",
                        },
                      },
                      [
                        _c("h1", { staticClass: "title" }, [
                          _vm._v(" Products "),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm.products.list.length > 0
              ? _c(
                  "v-row",
                  { attrs: { justify: "center" } },
                  _vm._l(_vm.products.list, function (product, index) {
                    return _c(
                      "v-col",
                      {
                        key: index,
                        class: {
                          "d-none":
                            _vm.userAccount &&
                            (product.action === "SignedOff" ||
                              product.action === "SignedIn"),
                        },
                        attrs: { xl: "3" },
                      },
                      [
                        _c("ProductCard", {
                          staticClass:
                            "d-flex flex-column rounded-lg fill-height",
                          attrs: {
                            activeProduct:
                              _vm.activeSubscription.object &&
                              _vm.activeSubscription.object.state === "Active"
                                ? _vm.activeSubscription.object.product
                                : undefined,
                            product: product,
                          },
                        }),
                      ],
                      1
                    )
                  }),
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }